@import "src/utils/utils";

$duration: 0.2s;

.button {
  border-radius: $border-radius;
  transition: background-color $duration;

  path {
    stroke: $imagen-light-purple;
    transition: stroke $duration;
  }

  &.current,
  &:hover {
    background-color: $imagen-light-purple;

    path {
      stroke: $imagen-purple;
    }
  }
}

[data-theme="imagen-purple"] {
  background-color: $imagen-purple !important;
  color: white !important;
  font-weight: 500;
}

.alerts {
  top: 0.25rem;
  right: 0.25rem;
  width: 1rem;
  height: 1rem;
  border-radius: 3rem;
}
