@import "src/utils/utils";

@mixin navConfig($textColor, $ruleColor) {
  &.nav {
    color: $textColor;
  }

  .logo path {
    fill: $textColor;
  }

  .verticalRule {
    background-color: $ruleColor;
  }
}

.normal {
  @include navConfig(white, $imagen-dark-purple);

  &.nav {
    background-color: $imagen-purple;
  }
}

.minimal {
  @include navConfig($imagen-purple, $imagen-gray-300);
}

.minimal-dark {
  @include navConfig($imagen-admin, $imagen-gray-600);
}

.dark {
  @include navConfig(white, $imagen-gray-900);

  &.nav {
    background-color: $imagen-admin;
  }

  .logo path {
    fill: $imagen-purple;
  }
}

.nav {
  height: $top-nav-height;
}

.verticalRule {
  width: 1px;
}

.wordmark {
  font-size: 0.875rem;
  letter-spacing: 0.25rem;
}
