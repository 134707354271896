.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;

  text-align: inherit;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.link-reset {
  color: inherit;
  text-decoration: inherit;
  background-color: inherit;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}
