@import "src/utils/utils";

.footer {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: white;
  }
}
