@import "src/utils/utils";

@mixin button($name, $bg, $text, $hoverBg, $hoverText) {
  &-#{$name} {
    background-color: $bg !important;
    color: $text !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s !important;
    border-color: $border-color !important;

    path {
      transition: stroke 0.2s;
      stroke: $text;
    }

    &:not([disabled]) {
      &:hover {
        background-color: $hoverBg !important;
        border-color: $hoverBg !important;
        color: $hoverText !important;

        path {
          stroke: white;
        }
      }

      &:active {
        background-color: darken($hoverBg, 7.5%) !important;
        border-color: darken($hoverBg, 7.5%) !important;
        color: $hoverText;

        path {
          stroke: $hoverText;
        }
      }
    }
  }
}

.button {
  border-radius: 4rem !important;
  box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);

  &-primary {
    background-color: $imagen-purple;
    color: white;

    path {
      stroke: white;
    }
  }

  &-outline {
    background: rgba(black, 0);
    border: 2px solid white;
    color: white;

    path {
      stroke: white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(white, 0.1) !important;
      border-color: white !important;
    }
  }

  &-translucent {
    background: rgba(white, 0.2);
    border: none;
    color: white;

    path {
      stroke: white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(white, 0.3) !important;
    }
  }

  @include button("light", white, $imagen-purple, $imagen-purple, white);

  @include button("lightAlt", white, $imagen-purple, rgba(white, 0.8), $imagen-purple);

  @include button("danger", white, $imagen-danger, $imagen-danger, white);
}

.softDisable {
  pointer-events: none;
}
