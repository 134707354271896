@import "src/utils/utils";

.wrapper {
  @include media-breakpoint-down(sm) {
    // TODO: this should be navbar height
    padding-bottom: 80px;
  }
}

.background {
  background: linear-gradient(180deg, $imagen-purple 0%, rgba($imagen-purple, 0.3) 100%);
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}
