@import "src/utils/utils";

.background {
  &-client,
  &-admin {
    background-color: $imagen-gray-200;
    background-position: center;
    background-size: cover;
  }

  &-consultant {
    background: linear-gradient(180deg, $imagen-purple 0%, rgba($imagen-purple, 0) 100%);
  }
}

.container {
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 20rem;
  }
}
