@import "src/utils/utils";

.heading {
  @include media-breakpoint-up(md) {
    font-size: rem(80px);
  }
  @include media-breakpoint-down(sm) {
    font-size: 15vw;
  }
}

.icon {
  min-width: 2rem;

  path {
    stroke: $imagen-dark-purple;
  }
}
