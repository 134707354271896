$imagen-purple: #857ceb;
$imagen-dark-purple: #4b3fc9;
$imagen-light-purple: #d8d8f9;
$imagen-admin: #333;

$imagen-gray-100: #f9fafb;
$imagen-gray-200: #e5e7eb;
$imagen-gray-300: #d1d5db;
$imagen-gray-400: #9ca3af;
$imagen-gray-500: #6b7280;
$imagen-gray-600: #4b5563;
$imagen-gray-700: #374151;
$imagen-gray-900: #111827;

$imagen-danger: #ff5e66;
$imagen-success-light: #d1fae5;
$imagen-success: #18db77;
$imagen-success-dark: #065f46;

$imagen-body: #fafafa;

$imagen-yellow: yellow;
