@import "src/utils/utils";

.image {
  background-position: center;
  background-size: cover;

  &-squircle {
    border-radius: rem(5px);
    border: 1px solid $border-color;
  }

  &-circle {
    border-radius: 50%;
  }
}

.fallback {
  border-radius: 50%;
}
