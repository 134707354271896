@import "src/utils/utils";

$fontWeights: 500, 600, 700, 800;

@each $fontWeight in $fontWeights {
  .font-weight-#{$fontWeight} {
    font-weight: $fontWeight;
  }
}

$fontSizes: 10, 11, 12, 13, 14, 18, 20, 24, 30, 35, 40, 50, 55, 75;

@each $fontSize in $fontSizes {
  .font-size-#{$fontSize} {
    font-size: remRaw($fontSize);
  }
}

.space-letters {
  letter-spacing: 0.05rem;
}

.condense-letters {
  letter-spacing: -0.05rem;
}

.break-word {
  word-wrap: break-word;
  hyphens: auto;
}
